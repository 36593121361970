<!--
 * @Author: dongjia
 * @Date: 2021-08-23 17:01:19
 * @LastEditTime: 2021-10-13 14:12:55
 * @LastEditors: aleaner
 * @Description: 回答详情
 * @FilePath: \saas-admin-vue\src\modules\community-admin\views\comment-admin\detail.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="detail-page" v-loading="loading">
    <div class="page-header" v-if="detail">
      <div class="user-info">
        <el-image class="user-avatar" :src="detail.avatar || defaultAvatar" fit="cover"></el-image>
        <div class="user-content">
          <div class="user-name">
            <div class="name">{{detail.nickname}}</div>
            <div class="black-tag" v-if="detail.black_status">黑名单</div>
          </div>
          <div class="post-time">{{detail.create_time}}</div>
        </div>
      </div>
      <div class="detail-handler">
        <el-dropdown trigger="click" @command="handleClickRow">
          <div class="el-dropdown-link">
            回答管理<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" v-if="detail.check_status === 2">
            <el-dropdown-item command="handleReply" v-if="detail.comment_level === 0">回复回答</el-dropdown-item>
            <el-dropdown-item command="handleRecommend" v-if="detail.comment_level === 0">
              {{detail.is_top?'取消设为置顶':'设为置顶'}}
            </el-dropdown-item>
            <el-dropdown-item command="handleShow">{{detail.is_show?'隐藏回答':'显示回答'}}</el-dropdown-item>
            <!-- <el-dropdown-item command="handleBlack" v-if="!detail.is_admin">
              {{detail.black_status?'恢复白名单':'加入黑名单'}}</el-dropdown-item> -->
          </el-dropdown-menu>
          <el-dropdown-menu v-else slot="dropdown">
            <el-dropdown-item command="handleChecked">通过审核</el-dropdown-item>
            <el-dropdown-item command="handleNoCheck" v-if="detail.check_status !== 1">不通过审核
            </el-dropdown-item>
            <el-dropdown-item command="handleBlack" v-if="!detail.is_admin">
              {{detail.black_status?'恢复白名单':'加入黑名单'}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="page-content" v-if="detail">
      <div class="comment-content">
        {{detail.content}}
      </div>
      <div class="reply-post">
        <p class="reply-title">回答帖子：</p>
        <div v-html="detail.contentText.content"></div>
        <!-- popover图片 -->
        <div v-if="detail.contentText.images && detail.contentText.images.length" class="popover-imgs">
          <el-image v-for="(img,index) in detail.contentText.images" class="popover-img cursor-pointer"
            :src="fixImageUrl(img.url)" @click="$previewImage(detail.contentText.images,index)" fit="cover"
            :key="index">
          </el-image>
        </div>
        <!-- popover视频 -->
        <div class="popover-video" v-if="detail.contentText.video && detail.contentText.video.thumbnail" :style="{
         marginTop: detail.content?'13px':''
       }">
          <el-image class="video-thumbnail" :src="fixImageUrl(detail.contentText.video.thumbnail)"
            fit="cover">
          </el-image>
          <div class="video-mask cursor-pointer" @click="handlePlay(detail.contentText.video)">
            <img class="video-play" src="@/base/assets/images/media/video-play@2x.png" />
          </div>
        </div>
      </div>
      <div class="content-number">
        回复：{{detail.replies_count}} 点赞：{{detail.likes_count}} 举报：{{detail.complaints_count}}
      </div>
    </div>
    <div class="page-list" v-if="detail && detail.ancestors">
      <el-table :data="[detail.ancestors]" class="thead-light" style="width: 100%">
        <el-table-column label="上级回答">
          <template slot-scope="{row,$index}">
            <div class="level-one-comment">{{row.nickname}}：{{row.content}}</div>
            <div class="comment-handler">
              <div class="comment-time">{{row.create_time}}</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <VideoDialog />
    <NoCheckedDialog v-model="openNoCheckedDialog" :Data="noCheckData" :updateList="getCommentDetail" />
    <ReplyDialog v-model="openReplyDialog" :Data="replyData" :updateList="getCommentDetail" />
  </div>
</template>

<script>
import { commentDetail } from "@/modules/consult/api/consult-content-comment/detail.js";
import {
  updateShow,
  updateTop,
  check,
  updateBlack,
} from "@/modules/consult/api/consult-content-comment/list.js";
import Pagination from "@/base/components/Default/Pagination";
import VideoDialog from "@/base/components/Preview/VideoViewer";
import NoCheckedDialog from "@/modules/consult/components/ConsultContentComment/NoCheckedDialog.vue";
import ReplyDialog from "@/modules/consult/components/ConsultContentComment/ReplyDialog.vue";

export default {
  components: { Pagination, VideoDialog, NoCheckedDialog, ReplyDialog },
  data() {
    return {
      loading: false,
      id: "",
      detail: null,
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png", // 默认头像,
      pageData: {},
      requestData: {
        id: "",
        page_size: 15,
        page: 1,
      },
      noCheckData: {},
      openNoCheckedDialog: false,
      replyData: {},
      openReplyDialog: false,
    };
  },
  created() {
    this.id = this.requestData.id = this.$route.params.id;
    this.getCommentDetail();
  },
  methods: {
    getCommentDetail() {
      this.loading = true;
      commentDetail({ id: this.id })
        .then((res) => {
          this.detail = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 视频播放
    handlePlay(data) {
      this.$store.commit("media/setIsOpenMediaVideoDialog", true);
      this.$store.commit("media/setDialogDetail", data);
    },
    // 分页查询
    changPage(e) {
      this.requestData = { ...this.requestData, ...e };
      this.getPostList(this.requestData);
    },
    // 点击下拉菜单
    handleClickRow(value) {
      this[value]();
    },
    // 修改置顶状态
    handleRecommend() {
      let data = {
        id: this.detail.id,
        is_top: this.detail.is_top === 1 ? 0 : 1,
      };
      this.loading = true;
      updateTop(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getCommentDetail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 修改显示状态
    handleShow() {
      let data = {
        id: this.detail.id,
        is_show: this.detail.is_show === 1 ? 0 : 1,
      };
      this.loading = true;
      updateShow(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getCommentDetail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 修改黑名单状态
    handleBlack() {
      let data = {
        id: this.detail.id,
        is_black: this.detail.black_status === 1 ? 0 : 1,
      };
      this.loading = true;
      updateBlack(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.getCommentDetail();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 快速回答
    handleReply() {
      this.replyData = {
        id: this.detail.id,
        content: "",
      };
      this.openReplyDialog = true;
    },
    // 通过审核
    handleChecked() {
      this.$msgbox
        .confirm("确定要通过该回答吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.loading = true;
          check({ id: this.detail.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.getCommentDetail();
            })
            .catch((err) => {
              this.getCommentDetail();
            });
        })
        .catch((err) => {});
    },
    // 不通过审核
    handleNoCheck() {
      this.noCheckData = {
        id: this.detail.id,
        reason: "",
      };
      this.openNoCheckedDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-page {
  padding: 4px;
  min-height: 400px;
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .user-info {
      display: flex;
      .user-avatar {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      .user-content {
        margin-left: 12px;
        .user-name {
          display: flex;
          align-items: center;
          .name {
            font-size: 16px;
            color: #000000;
            line-height: 16px;
          }
          .black-tag {
            margin-left: 8px;
            background: #909090;
            border-radius: 3px;
            padding: 1px 3px;
            font-size: 12px;
            color: #ffffff;
            line-height: 14px;
          }
        }
        .post-time {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #969799;
          line-height: 12px;
          margin-top: 8px;
        }
      }
    }
    .detail-handler {
      .el-dropdown-link {
        padding: 10px 15px;
        font-size: 12px;
        color: #ffffff;
        line-height: 12px;
        background: #3576ff;
        border-radius: 3px;
      }
    }
  }
  .page-content {
    margin-top: 18px;
    margin-left: 48px;
    .comment-content {
      font-size: 14px;
      color: #353535;
      line-height: 25px;
    }
    .reply-post {
      margin-top: 24px;
      padding: 20px;
      background: #f2f3f5;
      .reply-title {
        font-size: 14px;
        font-weight: bold;
        color: #3a3a3a;
        line-height: 20px;
        margin-bottom: 13px;
      }
    }
    .content-number {
      margin-top: 22px;
      font-size: 14px;
      color: #353535;
      line-height: 25px;
    }
  }
  .page-list {
    margin-top: 26px;
    .level-one-comment {
      font-size: 14px;
      color: #606266;
      line-height: 14px;
    }
    .comment-handler {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-size: 14px;
      line-height: 14px;
      .comment-time {
        color: #9a9a9a;
      }
      .reply-comment {
        margin-left: 20px;
        color: #3576ff;
      }
      .del-comment {
        margin-left: 12px;
        color: #d61919;
      }
    }
    .level-two-comment {
      margin-top: 22px;
      background: #f2f3f5;
      padding: 18px;
      .two-level-comment-item + .two-level-comment-item {
        margin-top: 18px;
      }
      .load-more {
        color: #3576ff;
        font-size: 14px;
        line-height: 14px;
        margin-top: 18px;
      }
      .two-level-comment-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .comment-content {
          font-size: 14px;
          color: #606266;
          line-height: 14px;
        }
        .handler {
          .del-comment {
            font-size: 14px;
            line-height: 14px;
            color: #d61919;
          }
        }
      }
    }
  }
}
.popover-imgs {
  display: flex;
  flex-wrap: wrap;
  .popover-img {
    margin-top: 14px;
    width: 90px;
    height: 90px;
    margin-right: 16px;
    border-radius: 4px;
  }
}

.popover-video {
  position: relative;
  width: 347px;
  height: 191px;
  .video-thumbnail {
    width: 100%;
    height: 100%;
  }
  .video-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);

    .video-play {
      display: inline-block;
      width: 46px;
      height: 46px;
    }
  }
  ::v-deep.el-table .cell {
    padding: 0 28px;
  }
}
</style>
